import React from "react";
import TableComponent from "../../components/table-component";
import TotalDataComponent from "../../components/total-data.component";
import {useDispatch, useSelector} from "react-redux";
import {setUserSelectedRowKeys} from "../../reducers/user.reducer";
import {Link} from "react-router-dom";
import {UserProfileDefault} from "../../utils/image";
import {convertDateToCustomFormat, statusRenderClassName} from "../../utils/general-variable";

export const UserList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysUser} = useSelector(state => state.userReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'User',
            classNameHeader: 'pl-5',
            width: 250,
            render: (record) => (
                <Link to={`/user/user-list/user-detail/${record?.id}`}>
                    <div className={"row items-center space-x-3 pl-5"}>
                        <img src={record.image_url || UserProfileDefault} className={"w-10 h-10 rounded-lg"} alt={"imageUser"}/>
                        <p className={"text-monstera capitalize hover:font-Montserrat-Bold hover:underline"}>{record.fullname}</p>
                    </div>
                </Link>
            )
        }, {
            name: 'Email',
            dataIndex: 'email',
            className: 'text-forged-steel'
        }, {
            name: 'Birth Date',
            render: (record) => (
                record?.birth_date ?
                    <div className={'col space-y-1 text-forged-steel'}>
                        <p>{convertDateToCustomFormat(record?.birth_date, 'YYYY/MM/DD')}</p>
                        <p>{record?.age} Years Old</p>
                    </div> :
                    <div>-</div>
            )
        }, {
            name: 'Role',
            dataIndex: 'role',
            className: 'text-forged-steel'
        }, {
            name: 'Created At',
            dataIndex: 'created_at',
            className: 'text-forged-steel',
            render: (created_at) => (
                    <p>{convertDateToCustomFormat(created_at, 'YYYY/MM/DD HH:mm')}</p>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            classNameHeader: 'text-center',
            render: (status) => (
                <div className={"row justify-center"}>
                    <div
                        className={`w-28 py-1 font-Montserrat-Bold capitalize rounded-md 
                        ${statusRenderClassName(status).className}`}>
                        <p className={"text-center"}>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setUserSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Users"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysUser}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}
